<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-half">
      <!-- <double-bounce v-if="isLoading"></double-bounce> -->
      <article class="tile is-child box detail-page-tile">
        <p class="title">Detail Info</p>
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input name="name"
              class="input is-capitalized"
              type="text"
              placeholder="Insurer Name"
              v-model="entity.name"
              :class="{'is-danger' : $v.entity.name.$error, 'is-warning' : nameExists}"
              @input="$v.entity.name.$touch(); checkNameExists()"
              maxlength="120"
              v-focus-inserted>
            <span class="help is-danger"
              v-if="$v.entity && !$v.entity.name.required">Name is required.
            </span>
            <span class="help is-warning"
              v-if="nameExists">Name already exists.
            </span>
          </div>
        </div>
        <div class="field">
          <div class="columns">
            <div class="column">
              <label class="label">ABN</label>
              <div class="field-body">
                <div class="field is-narrow">
                  <div class="control has-icons-right">
                    <the-mask v-if="$company.info.countryCode === 'AU'"
                      type="text"
                      class="input"
                      placeholder="ABN"
                      v-model="entity.abn"
                      :mask="['## ### ### ###']"
                      :class="{'is-danger' : $v.entity.abn.$error}"
                      @input.native="$v.entity.abn.$touch()" />
                    <input v-else
                      v-model="entity.abn"
                      id="abn"
                      ref="abn"
                      class="input is-uppercase"
                      maxlength="12"
                      @input="$v.entity.abn.$touch()">
                    <span v-if="!$v.entity.abn.$error && entity.abn && $company.info.countryCode === 'AU'"
                      class="icon has-text-success is-right">
                      <i class="mdi mdi-check mdi-18px" />
                    </span>
                    <span class="help is-danger"
                      v-if="$v.entity && !$v.entity.abn.minLength">ABN must be at least {{ $v.entity.abn.$params.minLength.min }} letters.</span>
                    <span class="help is-danger"
                      v-else-if="$v.entity && !$v.entity.abn.validAbn">ABN format is invalid.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <label class="label">ARBN</label>
              <div class="field-body">
                <div class="field is-narrow">
                  <div class="control has-icons-right">
                    <the-mask type="text"
                      class="input"
                      placeholder="ARBN"
                      v-model="entity.arbn"
                      :mask="['### ### ###']"
                      :class="{'is-danger' : $v.entity.arbn.$error}"
                      @input.native="$v.entity.arbn.$touch()" />
                    <span v-if="!$v.entity.arbn.$error && entity.arbn"
                      class="icon has-text-success is-right">
                      <i class="mdi mdi-check mdi-18px" />
                    </span>
                    <span class="help is-danger"
                      v-if="$v.entity && !$v.entity.arbn.minLength">ARBN must be at least {{ $v.entity.arbn.$params.minLength.min }} letters.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">G/L Account</label>
          <div class="control">
            <multiselect v-if="glAccountListCombo"
              v-model="selectedGlAccountObject"
              :options="glAccountListCombo"
              placeholder="Select account"
              label="displayName"
              track-by="accountNo"
              deselect-label="Can't remove this value"
              :allow-empty="false">
              <span class="has-text-danger"
                slot="noResult">G/L account not found.</span>
            </multiselect>
          </div>
        </div>
        <div class="field">
          <label class="label">Repairer ID</label>
          <div class="field-body">
            <div class="field is-narrow">
              <div class="control">
                <input class="input"
                  type="text"
                  placeholder="Repairer ID"
                  v-model="entity.repairerId">
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="columns">
            <div class="column is-narrow">
              <label class="label">BSB</label>
              <div class="field-body">
                <div class="field is-narrow">
                  <div class="control">
                    <the-mask type="text"
                      class="input"
                      placeholder="BSB"
                      v-model="entity.bsb"
                      :mask="['###-###']"
                      :class="{ 'is-danger' : $v.entity.bsb.$error }"
                      @input.native="$v.entity.bsb.$touch()" />
                    <span class="help is-danger"
                      v-if="$v.entity && !$v.entity.bsb.minLength">BSB must be at least {{ $v.entity.bsb.$params.minLength.min }} letters.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <label class="label">Account No.</label>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input uppercase"
                      type="text"
                      placeholder="Account No."
                      v-model="entity.accoutNo">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pretty p-icon p-curve p-smooth p-bigger">
          <input type="checkbox"
            v-model="entity.exported"
            :disabled="!$userInfo.isSupportUser">
          <div class="state p-primary">
            <i class="icon mdi mdi-check" />
            <label>Exported</label>
          </div>
        </div>
        <div class="is-divider" />
        <div v-if="$user.hasDelete($route.meta.id)"
          class="buttons has-addons is-centered">
          <span class="button"
            :class="{ 'is-success is-selected' : entity.active }"
            @click="toggleActive(true)">
            <span class="icon is-small">
              <i class="mdi mdi-check" />
            </span>
            <span>Active</span>
          </span>
          <span class="button"
            :class="{ 'is-danger is-selected' : !entity.active }"
            @click="toggleActive(false)">
            <span class="icon is-small">
              <i class="mdi mdi-close" />
            </span>
            <span>In-Active</span>
          </span>
        </div>
      </article>
    </div>
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Times &amp; Method</p>
        <label class="label">Labour Times</label>
        <div class="field is-grouped">
          <div class="control">
            <div class="select">
              <select v-model="entity.labourType"
                class="select-width">
                <option v-for="(labourType, propertyName, index) in labourTimesTypeEnum"
                  :key="index"
                  :value="propertyName">
                  {{ labourType }}
                </option>
              </select>
            </div>
          </div>
          <div v-show="isNtar"
            class="field has-checkradio">
            <div class="control">
              <input class="is-checkradio is-primary"
                :class="{ 'has-background-color': entity.autoPaintLoading }"
                id="auto-paint-loading"
                type="checkbox"
                name="auto-paint-loading"
                v-model="entity.autoPaintLoading">
              <label for="auto-paint-loading">Auto Paint Loading</label>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="columns">
            <div class="column is-narrow">
              <label class="label">E-Com Type</label>
              <div class="control">
                <div class="select"
                  :class="{ 'is-disabled': entity.arnie }">
                  <select v-model="ecomType"
                    :disabled="entity.arnie"
                    class="select-width">
                    <option :value="ecomTypeEnum.None" />
                    <option :value="ecomTypeEnum.ORM">ORM</option>
                    <option :value="ecomTypeEnum.PNET">PNET</option>
                    <option :value="ecomTypeEnum.EstImage">EstImage</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column">
              <label class="label">Arnie Brand</label>
              <div class="control">
                <div class="select"
                  :class="{ 'is-disabled': (entity.pnet || entity.orm) }">
                  <select v-model="entity.insurerBrand"
                    @change="onChangeInsurerBrand($event)"
                    :disabled="(entity.pnet || entity.orm)"
                    class="select-width">
                    <option />
                    <option v-for="(brand, index) in arnieBrands"
                      :key="brand"
                      :value="index + 1">
                      {{ brand }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Template</label>
          <div class="control">
            <multiselect v-if="quoteTemplateListCombo"
              v-model="selectedTemplateObject"
              :options="quoteTemplateListCombo"
              placeholder="Select quote template"
              label="name"
              track-by="id">
              <span class="has-text-danger"
                slot="noResult">Template not found.</span>
            </multiselect>
          </div>
        </div>
        <div class="is-divider" />
        <div class="field">
          <label class="label">Excess Type</label>
          <div class="control">
            <div class="pretty p-default p-round p-smooth">
              <input id="chk-pretty-excess-type"
                name="chk-pretty-excess-type"
                :value="false"
                v-model="entity.excessGST"
                type="radio">
              <div class="state p-primary">
                <label>Excess w/o GST</label>
              </div>
            </div>
            <div class="pretty p-default p-round p-smooth">
              <input id="chk-pretty-excess-type"
                name="chk-pretty-excess-type"
                :value="true"
                v-model="entity.excessGST"
                type="radio">
              <div class="state p-primary">
                <label>Excess with GST</label>
              </div>
            </div>
          </div>
        </div>
        <div class="is-divider" />
        <div class="field">
          <label class="label">Quoting Method</label>
          <div class="control">
            <div class="pretty p-default p-round p-smooth">
              <input id="chk-pretty-quotemethod1"
                name="chk-pretty-quotemethod1"
                :value="'H'"
                v-model="entity.quotingMethod"
                type="radio">
              <div class="state p-primary">
                <label>Hour</label>
              </div>
            </div>
            <div class="pretty p-default p-round p-smooth">
              <input id="chk-pretty-quotemethod2"
                name="chk-pretty-quotemethod2"
                :value="'D'"
                v-model="entity.quotingMethod"
                type="radio">
              <div class="state p-primary">
                <label>Dollar</label>
              </div>
            </div>
          </div>
        </div>
        <div class="is-divider" />
        <div class="field">

          <div class="columns">
            <div class="column is-narrow">
              <label class="label">AudaNet Settings </label>
              <div class="control">
                <div class="pretty p-switch p-fill">
                  <input type="checkbox"
                    @change="audaNetLockTypeOnChange"
                    :checked="entity.audaNetLockType ? true : false">
                  <div class="state p-primary">
                    <label>{{ startCase(Object.keys(audaNetLockTypes)[entity.audaNetLockType]) }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="column">
              <label class="label">AudaNet Work Provider Map</label>
              <div class="control">
                <div class="select">
                  <select v-model="entity.audaNetWorkProviderMapping"
                    class="select-width">
                    <option />
                    <option v-for="(provider, index) in entity.workProviders"
                      :key="index"
                      :value="provider">
                      {{ provider }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="is-divider" />
        <div class="field is-flex">
          <div>
            <label class="label">Show In Scope</label>
            <div class="control">
              <div class="pretty p-switch p-fill">
                <input type="checkbox"
                  @change="inScopeOnChange"
                  :checked="!!entity.inScope">
                <div class="state p-primary">
                  <label>{{ entity.inScope ? "On" : "Off" }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="is-divider-vertical" />
          <div class="">
            <label class="label">
              <span content="Auto transition job stage to Booked In when setting Estimated Job Start/End"
                v-tippy="{ enabled: true }">Auto Booked In
              </span>
            </label>
            <div class="control">
              <div class="pretty p-switch p-fill"
                content="Auto transition job stage to Booked In when setting Estimated Job Start/End"
                v-tippy="{ enabled: true }">
                <input type="checkbox"
                  @change="autoBookedInOnChange"
                  :checked="entity.autoBookedInForDueInOut"
                  v-model="entity.autoBookedInForDueInOut">
                <div class="state p-primary">
                  <label>{{ entity.autoBookedInForDueInOut ? "On" : "Off" }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="is-divider-vertical" />
          <div class="">
            <label class="label">
              <span>
                Show Damage Severity
              </span>
            </label>
            <div class="control">
              <div class="pretty p-switch p-fill">
                <input type="checkbox"
                  v-model="entity.damageSeverity">
                <div class="state p-primary">
                  <label>{{ entity.damageSeverity ? "On" : "Off" }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hasPdr"
          class="is-divider" />
        <div v-if="hasPdr"
          class="field">
          <label class="label">PDR Type</label>
          <div class="control">
            <div v-for="(pdrType, propertyName, index) in pdrEnum"
              :key="index"
              class="pretty p-default p-round p-smooth">
              <input :id="`chk-pretty-pdr${index}`"
                :name="`chk-pretty-${index}`"
                :value="pdrType"
                v-model="entity.pdrType"
                type="radio">
              <div class="state p-primary">
                <label :for="`pdr${index}`">{{ propertyName }}</label>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import InsurerService from './InsurerService'
import { TheMask } from 'vue-the-mask'
import Multiselect from 'vue-multiselect'
import { EventHubTypes, LabourTimeTypes, PdrTypes, EcomTypes, FlexiQuoteModuleTypes, AudaNetLockTypes } from '@/enums'
import { FocusInserted } from '@/components/directives'
import { QuoteTemplateTypeKeys } from '@/enums/QuoteTemplateTypes'
import _debounce from 'lodash.debounce'
import _startCase from 'lodash.startcase'

export default {
  name: 'InsurerDetail',
  inject: ['$vv'],
  directives: {
    FocusInserted
  },
  components: {
    TheMask,
    Multiselect
  },
  props: {
    insurerId: String,
    entity: null
  },
  data: function () {
    return {
      glAccountListCombo: null,
      quoteTemplateListCombo: null,
      arnieBrands: null,
      protoData: {},
      invalids: [],
      selectedTemplateObject: {},
      selectedGlAccountObject: {},
      isLoading: true,
      modules: [],
      defaultConsumables: [],
      nameExists: false
    }
  },
  computed: {
    ecomType: {
      get: function () {
        if (this.entity.pnet) {
          return this.ecomTypeEnum.PNET
        } else if (this.entity.orm) {
          return this.ecomTypeEnum.ORM
        } else if (this.entity.estimage) {
          return this.ecomTypeEnum.EstImage
        } else {
          return this.ecomTypeEnum.None
        }
      },
      set: function (newValue) {
        if (newValue === this.ecomTypeEnum.None) {
          this.entity.pnet = false
          this.entity.orm = false
          this.entity.estimage = false
        } else if (newValue === this.ecomTypeEnum.PNET) {
          this.entity.pnet = true
          this.entity.orm = false
          this.entity.excessGST = true
          this.entity.estimage = false
          this.entity.quotingMethod = 'D'
        } else if (newValue === this.ecomTypeEnum.ORM) {
          this.entity.pnet = false
          this.entity.orm = true
          this.entity.excessGST = false
          this.entity.estimage = false
          this.entity.quotingMethod = 'H'
        } else if (newValue === this.ecomTypeEnum.EstImage) {
          this.entity.pnet = false
          this.entity.orm = false
          this.entity.excessGST = false
          this.entity.estimage = true
          this.entity.quotingMethod = 'H'        }
      }
    },
    ecomTypeEnum: function () {
      return EcomTypes
    },
    pdrEnum: function () {
      return PdrTypes
    },
    labourTimesTypeEnum() {
      return LabourTimeTypes
    },
    isNtar() {
      return (
        this.entity &&
        (this.entity.labourType === LabourTimeTypes.NTAR || this.entity.labourType === LabourTimeTypes.LTAR || this.entity.labourType === LabourTimeTypes.eMTA)
      )
    },
    hasPdr() {
      if (!this.modules) {
        return false
      }
      const findPdr = (obj) => {
        return obj.moduleDescription === FlexiQuoteModuleTypes.PdrQuote
      }
      var index = this.modules.find(findPdr)
      return index !== undefined
    },
    $v() {
      return this.$vv ? this.$vv : this.$v
    },
    audaNetLockTypes() {
      return AudaNetLockTypes
    },
    quoteTemplateTypeKeys() {
      return QuoteTemplateTypeKeys
    }
  },
  watch: {
    selectedTemplateObject: function (newVal, oldVal) {
      if (newVal != null) {
        this.entity.templateId = newVal.id
      } else {
        this.entity.templateId = null
      }
    },
    selectedGlAccountObject: function (newVal, oldVal) {
      if (newVal != null) {
        this.entity.insurerGLCode = newVal.accountNo
      } else {
        this.entity.insurerGLCode = ''
      }
    },
    'entity.labourType': function (newVal, oldVal) {
      this.entity.autoPaintLoading = this.isNtar
      this.assignConsumables(newVal)
    },
    'entity.insurerGLCode': function (newVal) {
      this.selectedGlAccountObject = this.glAccountListCombo.find((g) => g.accountNo === newVal)
    },
    'entity.templateId': function (newVal) {
      this.selectedTemplateObject = this.quoteTemplateListCombo.find((t) => t.id === newVal)
    }
  },
  created() {
    this.getData()
    this.getModules()
    this.getDefaultConsumables()
    this.$eventHub.$on(EventHubTypes.EntitySaved, (data) => {
      this.onEntitySaved(data)
    })
  },
  mounted() {
    this.isLoading = false
    this.$v.detailGroup.$touch()
  },
  methods: {
    async getData() {
      // eslint-disable-next-line
      const templates = await InsurerService.getQuoteTemplateListComboProto()
      ;[this.glAccountListCombo, this.arnieBrands, this.quoteTemplateListCombo] = await Promise.all([
        InsurerService.getGlAccountListComboProto(),
        InsurerService.getArnieBrand(),
        templates.filter(template => template.quoteType == this.quoteTemplateTypeKeys.Insurer)
      ])
      this.selectedGlAccountObject = this.glAccountListCombo.find((g) => g.accountNo === this.entity.insurerGLCode)
      this.selectedTemplateObject = this.quoteTemplateListCombo.find((t) => t.id === this.entity.templateId)
    },
    onChangeInsurerBrand(event) {
      this.entity.arnie = this.entity.insurerBrand !== '' && this.entity.insurerBrand !== '0'
    },
    checkInUse() {
      this.$emit('entityDelete')
    },
    async getModules() {
      const moduleKey = `${this.$userInfo.sessionId}|${this.$route.meta.mkey}`
      this.modules = JSON.parse(sessionStorage.getItem(moduleKey))
      if (!this.modules) {
        this.modules = await InsurerService.getModules()
        sessionStorage.setItem(moduleKey, JSON.stringify(this.modules))
      }
    },
    async getDefaultConsumables() {
      const consumableKey = `${this.$userInfo.sessionId}|${this.$route.meta.ckey}`

      this.defaultConsumables = JSON.parse(sessionStorage.getItem(consumableKey))
      if (!this.defaultConsumables) {
        this.defaultConsumables = await InsurerService.getDefaultNtarConsumables()
        sessionStorage.setItem(consumableKey, JSON.stringify(this.defaultConsumables))
      }
    },
    checkNameExists: _debounce(async function () {
      await this.assertNameExists()
    }, 500),
    async assertNameExists() {
      this.nameExists = await InsurerService.assertNameExists(this.entity.insurerId, this.entity.name)
    },
    assignConsumables(type) {
      const findCon = (typeId) => {
        return function (obj) {
          return obj.typeId === typeId
        }
      }
      if (type === LabourTimeTypes.NTAR || type === LabourTimeTypes.LTAR) {
        this.entity.panelCons = this.defaultConsumables.find(findCon(1)).value
        this.entity.paintCons = this.defaultConsumables.find(findCon(3)).value
        this.entity.panelConsMin = this.defaultConsumables.find(findCon(2)).value
      } else if (type === LabourTimeTypes.eMTA) {
        this.entity.panelCons = this.defaultConsumables.find(findCon(5)).value
        this.entity.paintCons = 0
        this.entity.panelConsMin = 0
      } else if (type === LabourTimeTypes.RACQ) {
        this.entity.paintCons = this.defaultConsumables.find(findCon(4)).value
        this.entity.panelCons = 0
        this.entity.panelConsMin = 0
      } else {
        this.entity.panelCons = 0
        this.entity.paintCons = 0
        this.entity.panelConsMin = 0
      }
    },
    onEntitySaved() {
      this.nameExists = false
    },
    getVuelidationErrors() {
      // console.log(this.$v.entity.$flattenParams())
      if (!(this.entity && this.$v.entity.$invalid)) {
        // console.log('no errors!!!')
        return []
      }
      const buffer = []
      Object.keys(this.$v.entity).forEach((key) => {
        // console.log(`${key} : ${typeof this.$v.entity[key]}`)
        if (!key.startsWith('$') && typeof this.$v.entity[key] === 'object' && this.$v.entity[key].$invalid) {
          // console.log(this.$v.entity[key].$invalid)
          buffer.push({
            type: key,
            message: 'vuelidate.' + key
          })
        }
      })
      return buffer
    },
    onReload() {
      this.selectedGlAccountObject = this.glAccountListCombo.find((g) => g.accountNo === this.entity.insurerGLCode)
      this.selectedTemplateObject = this.quoteTemplateListCombo.find((t) => t.id === this.entity.templateId)
    },
    toggleActive(active) {
      this.entity.active = active
      if (active) {
        this.assertNameExists()
      } else {
        this.checkInUse()
      }
    },
    startCase(value) {
      return _startCase(value)
    },
    audaNetLockTypeOnChange(event) {
      this.entity.audaNetLockType = event.target.checked ? AudaNetLockTypes.LockOnRFQ : AudaNetLockTypes.NeverLock
    },
    inScopeOnChange(event) {
      this.entity.inScope = !!event.target.checked
    },
    autoBookedInOnChange(event) {
      this.entity.autoBookedInForDueInOut == event.target.checked
    }
  },
  beforeRouteLeave: async function (to, from, next) {
    next()
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';
.is-divider {
  margin: 1.2em 0;
}
input.uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
// Vertical align middle checkbox
.field.has-checkradio {
  .control {
    position: relative;
    top: 48%;
    transform: translateY(-50%);
  }
}
.select-width {
  min-width: 8.125em;
}
</style>
