<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          {{ entityName }}
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: routeTypes.InsurerDetail.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : $v.detailGroup.$error }"
            :data-tooltip="[ $v.detailGroup.$error ? 'Validation errors' : '' ]"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.detailGroup.$error }"
              :data-badge="null">
              Detail Info
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.InsurerRatesMarkups.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            exact>Rates &amp; Markups
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.InsurerAddress.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : $v.addressesGroup.$error }"
            :data-tooltip="[ $v.addressesGroup.$error ? 'Validation errors' : '' ]"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.addressesGroup.$error }"
              :data-badge="$v.addressesGroup.$error ? '' : null">
              Addresses
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.InsurerPhoneEmail.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : $v.emailsPhonesGroup.$error }"
            :data-tooltip="[ $v.emailsPhonesGroup.$error ? 'Validation errors' : '' ]"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.emailsPhonesGroup.$error }"
              :data-badge="$v.emailsPhonesGroup.$error ? '' : null">
              Phones/Emails
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.InsurerContacts.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : $v.contactsGroup.$error }"
            :data-tooltip="[ $v.contactsGroup.$error ? 'Validation errors' : '' ]"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.contactsGroup.$error }"
              :data-badge="$v.contactsGroup.$error ? '' : null">
              Contacts
            </span>
          </router-link>
        </li>
      </ul>
      <p v-if="isNtar"
        class="menu-label">
        NTAR
      </p>
      <ul v-if="isNtar"
        class="menu-list">
        <li>
          <router-link :to="{ name: routeTypes.InsurerConsumables.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : isError.CMI }"
            :data-tooltip="[ isError.CMI ? 'Validation errors' : '' ]"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': isError.CMI }"
              :data-badge="isError.CMI ? '' : null">
              Consumables
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.InsurerLoadingValues.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : isError.LVI }"
            :data-tooltip="[ isError.LVI ? 'Validation errors' : '' ]"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': isError.LVI }"
              :data-badge="isError.LVI ? '' : null">
              Loading Values
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.InsurerPaintMaterials.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : isError.PMI }"
            :data-tooltip="[ isError.PMI ? 'Validation errors' : '' ]"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': isError.PMI }"
              :data-badge="isError.PMI ? '' : null">
              Paint Materials
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.InsurerSpecialRates.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            :class="{ 'tooltip is-tooltip-danger is-tooltip-right' : isError.SPI }"
            :data-tooltip="[ isError.SPI ? 'Validation errors' : '' ]"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': isError.SPI }"
              :data-badge="isError.SPI ? '' : null">
              Special Rates
            </span>
          </router-link>
        </li>
      </ul>
      <p class="menu-label">
        Others
      </p>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: routeTypes.InsurerAutoItems.name, params: { insurerId : $route.params.insurerId }, query: $route.query }"
            active-class="is-active"
            exact>Auto Items
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import InsurerRoutes from './route-types'

export default {
  name: 'InsurerSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    isNtar: {
      type: Boolean,
      default: false
    },
    entityName: {
      type: String,
      default: ''
    },
    isError: null
  },
  data() {
    return {
      activeMenuItem: 'detail',
      isActiveClass: 'is-active'
    }
  },
  computed: {
    $v() {
      return this.$vv
    },
    routeTypes() {
      return InsurerRoutes
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
aside {
  min-height: 100%; // calc(100vh - 7em);
  // min-width: 18vw;
}
</style>
