<template>
  <div>
    <app-detail-header :title="`Insurer ${$route.meta.title}`"
      :show-toggle="true"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="entity && entity.active && !entity.isNew"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <insurer-side-menu v-if="entity"
          ref="sideMenu"
          :is-ntar="isNtar"
          :entity-name="entity.name"
          :is-error="isConsumableError" />
      </div>
      <div class="column">
        <router-view v-if="entity"
          :entity="entity"
          :is-error="isConsumableError"
          :is-edit-values="isEditValues"
          @entityDelete="onEntityDelete()"
          ref="currentChild"
          :is-tiled="false"
          asset-type="4"
          :asset-id="entity.insurerId"
          :key="$route.fullPath"
          :route-name="$route.name"
          @add-versions="addVersions"
          @on-edit="onEdit"
          @set-error="checkError" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(false, false, deleteAndSave)"
      @cancel="deleteEntity(false, true, false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Insurer is in use</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ entity.name }}</span> is in use. Set to
        <span class="tag is-danger">In-Active</span> anyway?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import InsurerSideMenu from './InsurerSideMenu'
import InsurerService from './InsurerService'
import AppDetailHeader from '@/components/AppDetailHeader'
import InsurerRoutes from './route-types'
import QuickInvoiceRoutes from '@/views/quickinvoice/route-types'
import QuoteRoutes from '@/views/quote/route-types'
import EventBus from '@/components/EventBus'
import InsurerValidation from './InsurerValidation'
import Guid from '@/components/Guid'
import HttpStatus from '@/components/http-status'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import { LabourTimeTypes, EventHubTypes, AppHeaderButtonTypes, AssetTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _debounce from 'lodash/debounce'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import { InsurerPayload, QuickInvoiceAssetPayload } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
// import Long from 'long'
// import ProtoBuf from 'protobufjs'

export default {
  name: 'InsurerView',
  components: {
    InsurerSideMenu,
    AppDetailHeader,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [InsurerValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String,
    name: String,
    iid: String
  },
  data() {
    return {
      entity: null,
      activeSideMenu: 'detail',
      initialising: true,
      selectedIndexByRoute: {
        InsurerConsumables: 0,
        InsurerLoadingValues: 0,
        InsurerPaintMaterials: 0,
        InsurerSpecialRates: 0
      },
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      isConfirmModalActive: false,
      toRoute: null,
      deleteAndSave: true,
      entityProto: null,
      isEditValues: false,
      isConsumableError: {
        isError: false,
        CMI: false,
        PMI: false,
        LVI: false,
        SPI: false
      }
    }
  },
  computed: {
    buttonCSS: function () {
      return 'is-inverted'
    },
    routeList() {
      return this.$route.matched
    },
    isNtar() {
      return (
        this.entity &&
        (this.entity.labourType === LabourTimeTypes.NTAR || this.entity.labourType === LabourTimeTypes.LTAR || this.entity.labourType === LabourTimeTypes.eMTA)
      )
    },
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error || this.$v.emailsPhonesGroup.$error || this.$v.addressesGroup.$error || this.$v.contactsGroup.$error
    }
  },
  watch: {
    entity: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 250),
      deep: true
    }
  },
  async created() {
    InsurerService.clearPreviousSessionStorage(this.$userInfo.sessionId)
    if (this.$route.query.mode === 'new') {
      this.$showSpinner('Creating...')
      const payload = new InsurerPayload(this.$route.query.name, false)
      await this.addStoreItem(payload)
      this.entity = _cloneDeep(this.currentSnapshot)
      this.persistQueries()
      this.replaceRoute(this.entity.insurerId)
      this.$hideSpinner()
    } else {
      this.getEntity()
    }
    // this.getEntityProto()
  },
  mounted() {},
  beforeDestroy() {
    // this.clearSessionStorage()
    // this.clearSnapshots(this.entity.insurerId)
  },
  methods: {
    async onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          // var url = `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx?reportname=rptTableInsurerDetails&CompanyID=${
          //   this.$userInfo.companyId
          // }&InsurerID=${this.entity.insurerId}`
          // window.open(url, '_blank')
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          this.deleteAndSave = true
          this.deleteEntity(true, false, true)
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      // Vue will not update child collections if structure is not declared. this.entity = null reset the references
      // this.entity = null
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSnapshots(this.$route.params.insurerId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.insurerId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        if (e.response) {
          EventBus.$emit('serviceError', e.response.status)
          this.$notification.openMessageXhrError('', e)
        } else {
          console.error(e)
        }
      } finally {
        this.$hideSpinner()
      }
    },
    // async getEntityProto() {
    //   this.entityProto = await InsurerService.getInsurerProto(this.$route.params.insurerId)
    //   // console.log(this.entityProto.InsurerID.hi.toString())
    //   // console.log(this.bytesToGuidString(this.bclGuidtoBytes(this.entityProto.InsurerID)))
    // },
    // bclGuidtoBytes(bclGuid) {
    //   const low = Long.fromString('4687570604103417836', true) // Long.fromString(bclGuid.lo.toString(), true)
    //   // console.log(low)
    //   const high = Long.fromString('17075442052373025196', true) // Long.fromString(bclGuid.hi.toString(), true)
    //   // console.log(high)
    //   const highBytes = high.toBytesLE()
    //   const lowBytes = low.toBytesLE()
    //   // console.log(lowBytes, highBytes)
    //   const bytes = lowBytes.concat(highBytes)
    //   // console.log(bytes)
    //   // console.log(this.bytesToGuidString(bytes))
    //   return bytes
    // },
    print() {
      const params = Object.assign({
        CompanyID: this.$userInfo.companyId,
        InsurerID: this.entity.insurerId
      })
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.insurerId
      emailer.subject = 'Insurer Table Detail'
      emailer.reportName = 'rptTableInsurerDetails'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    bytesToGuidString(byteArray) {
      return Array.from(byteArray, function (byte) {
        return ('0' + (byte & 0xff).toString(16)).slice(-2)
      })
        .join('')
        .replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5')
    },
    guidStringToBytes(guid) {
      var bytes = []
      guid.split('-').map((number, index) => {
        var bytesInChar = index < 3 ? number.match(/.{1,2}/g).reverse() : number.match(/.{1,2}/g)
        bytesInChar.map((byte) => {
          bytes.push(parseInt(byte, 16))
        })
      })
      return bytes
    },
    async save(isDelete) {
      const message = 'Validation errors. Please fix before saving'
      const title = 'Insurer'
      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))
        if (!this.validateError && !this.isConsumableError.isError) {
          let response
          if (this.entity.isNew) {
            response = await InsurerService.postInsurer(this.entity)
          } else if (this.snapshotDiff) {
            response = await InsurerService.putInsurer(_cloneDeep(this.entity), this.snapshotDiff)
          } else {
            this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
            return false
          }
          if (this.isSaveContinue) {
            this.$notification.openNotificationWithType('success', title, isDelete ? 'Insurer set to In-Active' : 'Save successful')
          } else if (response.status === HttpStatus.NO_CONTENT) {
            await this.getEntity(true) // Refresh page data
            // this.dataChanged = false
            this.$notification.openNotificationWithType('success', title, isDelete ? 'Insurer set to In-Active' : 'Save successful')
          } else if (response.status === HttpStatus.CREATED) {
            await this.getEntity(true)
            this.$notification.openNotificationWithType('success', title, 'New insurer added')
          }
          this.$eventHub.$emit(EventHubTypes.EntitySaved)
          this.isEditValues = false
          return true
        } else {
          this.$notification.openNotificationWithType('danger', title, message)
          return false
        }
      } catch (e) {
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('', e)
        }
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      let returnUrl = ''
      if (this.returnUrl) {
        if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
          this.isUnsavedModalActive = true
          return
        }
        this.$showSpinner('Returning...')
        if (this.iid) {
          // Return to Quick Invoice
          returnUrl = `${this.returnUrl}&type=${this.type}&iid=${this.iid}`
        } else {
          // Return to Quote
          const refreshId = Guid.createShortGuid(this.entity.insurerId)
          returnUrl = `${this.returnUrl}&refresh=insurer&refreshId=${refreshId}`
        }
        this.clearSessionStorage()
        this.clearSnapshots(this.entity.insurerId)
        window.location.href = returnUrl
      } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
        this.$router.push({
          path: this.returnRoute.path
        })
      } else if (this.toRoute !== null) {
        this.$router.push(this.toRoute.fullPath)
      } else {
        this.$router.push({ name: InsurerRoutes.InsurerListView.name })
      }
      // console.log(returnUrl)
    },
    replaceRoute(id) {
      // console.log(this.returnUrl)
      const newMeta = Object.assign(this.$route.meta, {
        isNew: true,
        returnUrl: this.returnUrl,
        type: this.type,
        name: this.name,
        iid: this.iid
      })
      this.$router.replace({
        name: InsurerRoutes.InsurerDetail.name,
        params: { insurerId: id },
        meta: newMeta
      })
    },
    gotoNew() {
      const id = Guid.newGuid()
      this.$router.push({
        name: InsurerRoutes.InsurerDetail.name,
        params: { insurerId: id },
        query: { mode: 'new' }
      })
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isConfirmModalActive = false
      this.isSaveContinue = false
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.getEntity(true)
    },
    async deleteEntity(check, value, saveEntity) {
      this.isConfirmModalActive = false
      const isInUse = check ? await InsurerService.assertUsed(this.entity.insurerId) : false
      if (isInUse) {
        this.isConfirmModalActive = true
      } else {
        this.entity.active = value
        if (saveEntity) {
          this.save(true)
        }
      }
    },
    async onEntityDelete() {
      this.deleteAndSave = false
      const isInUse = await InsurerService.assertUsed(this.entity.insurerId)
      if (isInUse) {
        this.isConfirmModalActive = true
      }
    },
    persistQueries() {
      if (this.isNew) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|insurer|isNew`, this.isNew)
      }
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|insurer|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|insurer|type`, this.type)
      }
      if (this.name) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|insurer|name`, this.name)
      }
      if (this.iid) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|insurer|iid`, this.iid)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|insurer|isNew`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|insurer|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|insurer|type`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|insurer|name`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|insurer|iid`)
    },
    clearSessionStorage() {
      if (this.$userInfo) {
        const moduleKey = `${this.$userInfo.sessionId}|${this.$route.meta.mkey}`
        const consumableKey = `${this.$userInfo.sessionId}|${this.$route.meta.ckey}`
        sessionStorage.removeItem(moduleKey)
        sessionStorage.removeItem(consumableKey)
        this.removeQueries()
      }
    },
    clearPreviousSessionStorage() {
      const regAll = new RegExp('insurer', 'i')
      const regCurrent = new RegExp(`${this.$userInfo.sessionId}`, 'i')
      Object.keys(sessionStorage)
        .filter(function (s) {
          return regAll.test(s) && !regCurrent.test(s)
        })
        .forEach(function (s) {
          // sessionStorage.removeItem(s)
        })
    },

    // Add Versions to all the consumables
    addVersions(value) {
      // Get Max Version form all the consumable
      const insurerConsumablesVersion = Math.max.apply(
        Math,
        this.entity.insurerConsumables.map(function (c) {
          return c.versionNo
        })
      )
      const insurerPaintMaterialVersion = Math.max.apply(
        Math,
        this.entity.insurerPaintMaterials.map(function (c) {
          return c.versionNo
        })
      )
      const insurerLoadingValuesVersion = Math.max.apply(
        Math,
        this.entity.insurerLoadingValues.map(function (c) {
          return c.versionNo
        })
      )
      const insurerSpecialRatesVersion = Math.max.apply(
        Math,
        this.entity.insurerSpecialRates.map(function (c) {
          return c.versionNo
        })
      )

      // If maxVersion doesn't match
      if (insurerConsumablesVersion !== value) {
        this.addInsurerConsumablesVersions(insurerConsumablesVersion, value)
      }
      if (insurerPaintMaterialVersion !== value) {
        this.addInsurerPaintMaterialsVersions(insurerPaintMaterialVersion, value)
      }
      if (insurerLoadingValuesVersion !== value) {
        this.addInsurerLoadingValuesVersions(insurerLoadingValuesVersion, value)
      }
      if (insurerSpecialRatesVersion !== value) {
        this.addInsurerSpecialRatesVersions(insurerSpecialRatesVersion, value)
      }
      this.isEditValues = true
    },
    addInsurerConsumablesVersions(currentVersion, maxVersion) {
      const prevVersion = this.entity.insurerConsumables.filter((i) => i.versionNo === currentVersion)

      for (var i = currentVersion + 1; i <= maxVersion; i++) {
        var newList = JSON.parse(JSON.stringify(prevVersion))
        newList.forEach((item) => {
          item.versionNo = i
          item.consumablesId = Guid.newGuid()
          item.isNew = true
          this.entity.insurerConsumables.push(item)
        })
      }
    },
    addInsurerPaintMaterialsVersions(currentVersion, maxVersion) {
      const prevVersion = this.entity.insurerPaintMaterials.filter((i) => i.versionNo === currentVersion)

      for (var i = currentVersion + 1; i <= maxVersion; i++) {
        var newList = JSON.parse(JSON.stringify(prevVersion))
        newList.forEach((item) => {
          item.versionNo = i
          item.paintTypeId = Guid.newGuid()
          item.isNew = true
          this.entity.insurerPaintMaterials.push(item)
        })
      }
    },
    addInsurerLoadingValuesVersions(currentVersion, maxVersion) {
      const prevVersion = this.entity.insurerLoadingValues.filter((i) => i.versionNo === currentVersion)

      for (var i = currentVersion + 1; i <= maxVersion; i++) {
        var newList = JSON.parse(JSON.stringify(prevVersion))
        newList.forEach((item) => {
          item.versionNo = i
          item.ntarLoadingValueId = Guid.newGuid()
          item.isNew = true
          this.entity.insurerLoadingValues.push(item)
        })
      }
    },
    addInsurerSpecialRatesVersions(currentVersion, maxVersion) {
      const prevVersion = this.entity.insurerSpecialRates.filter((i) => i.versionNo === currentVersion)

      for (var i = currentVersion + 1; i <= maxVersion; i++) {
        var newList = JSON.parse(JSON.stringify(prevVersion))
        newList.forEach((item) => {
          item.versionNo = i
          item.specialRatesId = Guid.newGuid()
          item.isNew = true
          this.entity.insurerSpecialRates.push(item)
        })
      }
    },
    onEdit(value) {
      this.isEditValues = !value
    },
    checkError() {
      const maxVersion = this.getMaximumVersion()

      const checkCMI = this.entity.insurerConsumables.find((i) => i.versionNo === maxVersion && i.value <= 0.0)
      const checkLVI = this.entity.insurerLoadingValues.find((i) => i.versionNo === maxVersion && i.value <= 0.0)
      const checkPMI = this.entity.insurerPaintMaterials.find((i) => i.versionNo === maxVersion && i.value <= 0.0)
      const checkSPI = this.entity.insurerSpecialRates.find((i) => i.versionNo === maxVersion && i.value <= 0.0)
      if (checkCMI || checkLVI || checkPMI || checkSPI) {
        this.isConsumableError.CMI = checkCMI ? true : false
        this.isConsumableError.LVI = checkLVI ? true : false
        this.isConsumableError.PMI = checkPMI ? true : false
        this.isConsumableError.SPI = checkSPI ? true : false

        this.isConsumableError.isError = true
      } else {
        this.isConsumableError.CMI = false
        this.isConsumableError.LVI = false
        this.isConsumableError.PMI = false
        this.isConsumableError.SPI = false
        this.isConsumableError.isError = false
      }
    },

    // Get Maximum values from all the Values
    getMaximumVersion() {
      let value = [
        Math.max.apply(
          Math,
          this.entity.insurerConsumables.map(function (c) {
            return c.versionNo
          })
        ),
        Math.max.apply(
          Math,
          this.entity.insurerPaintMaterials.map(function (c) {
            return c.versionNo
          })
        ),
        Math.max.apply(
          Math,
          this.entity.insurerLoadingValues.map(function (c) {
            return c.versionNo
          })
        ),
        Math.max.apply(
          Math,
          this.entity.insurerSpecialRates.map(function (c) {
            return c.versionNo
          })
        )
      ]

      return Math.max.apply(Math, value)
    }
  },
  beforeRouteLeave: function (to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    console.log(this.snapshotDiff)
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      if (to.name === QuickInvoiceRoutes.QuickInvoiceDetail.name) {
        const payload = new QuickInvoiceAssetPayload(this.entity.insurerId, AssetTypes.Insurer, this.entity.insurerGLCode)
        this.setQuickInvoiceAsset(payload)
      } else if (to.name === QuoteRoutes.QuoteDetail.name) {
        this.setQuoteInsurer(this.entity)
      }
      // Clean vuex store for now
      this.clearSnapshots(this.entity.insurerId)
      this.clearSessionStorage()
      this.setReturnRoute({})
      next()
    }
  }
}
</script>
